<template>
  <div class="CardPagamentoContainer">
    <v-container fluid px-0 py-2 class="Container_Nivel2_Pagamento">
      <v-row justify="start" class="mx-0">
        <v-col cols="12" sm="6" md="3" class="ml-3">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" align-self="center">
          <v-tooltip bottom v-if="canEdit && implantacao.indImportacaoLiquidacao">
            <template v-slot:activator="{ on }">
              <v-btn id="btn_importar"
                icon
                class="mx-0 pt-1"
                v-on="on"
                @click="abrirModalImportacao">
                <v-icon>backup</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('label.importar') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row justify="center" class="mx-0">
        <v-container fluid grid-list-md class="Container_Cards">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start" class="mx-0">
                <v-col
                  v-for="item in props.items"
                  :key="item.id"
                  cols="12"
                  sm="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_Pagamento_${isVisaoStatus ? item.status : 'SEM_STATUS'} elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Pagamento">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-card-title class="Card_Title pa-2 justify-center" v-on="on" @click="selectItem(item)">
                            <p style="text-align: center;">
                              <br />{{ item.focoId }}
                              <span>
                                <br />{{ item.focoNome | truncate(28) }}
                                <br />{{ item.codAcaoPrevia ? `Cod. Planejamento Massivo: ${item.codAcaoPrevia}` : `Cod. Ação Tática: ${item.codAcao}` }}
                                <br />
                                <br />{{ $tc('label.periodo_apuracao', 1) }}
                                <br />{{item.dataInicio}} à {{item.dataFim}}
                                <br />
                                <div nowrap class="headline font-weight-light">
                                  {{ getMoney(item.valor) }}
                                </div>
                              </span>
                            </p>
                          </v-card-title>
                        </template>
                        <span v-if="(isVisaoStatus && (item.pagamentosAprovados === 0)
                          && (item.pagamentosReprovados === 0)
                          && (item.pagamentosCancelados === 0)) ">
                          Status: {{ $tc('status_entidade.'+item.status.toLowerCase()) }}
                        </span>
                        <span v-if="(!isVisaoStatus && (item.pagamentosAprovados === 0)
                          && (item.pagamentosReprovados === 0)
                          && (item.pagamentosCancelados === 0)) ">
                          Ainda não existem pagamentos aprovados, reprovados ou cancelados
                        </span>
                        <span v-if="((item.pagamentosAprovados === item.quantidadeAcoes)
                          && (item.quantidadeAcoes > 0)
                          && (item.pagamentosReprovados === 0)
                          && (item.pagamentosCancelados === 0)) ">
                          Status: Pagamentos Aprovados
                        </span>
                        <span v-if="((item.pagamentosReprovados === item.quantidadeAcoes)
                          && (item.quantidadeAcoes > 0)
                          && (item.pagamentosAprovados === 0)
                          && (item.pagamentosCancelados === 0)) ">
                          Status: Pagamentos Reprovados
                        </span>
                        <span v-if="((item.pagamentosCancelados === item.quantidadeAcoes)
                          && (item.quantidadeAcoes > 0)
                          && (item.pagamentosReprovados === 0)
                          && (item.pagamentosAprovados === 0)) ">
                          Status: Pagamentos Canceladas
                        </span>
                        <span v-if="((item.pagamentosAprovados > 0)
                          && ((item.pagamentosAprovados !== item.quantidadeAcoes) ||
                              ((item.pagamentosReprovados > 0) || (item.pagamentosCancelados > 0)))
                          && (item.pagamentosAprovados >= item.pagamentosReprovados)
                          && (item.pagamentosAprovados >= item.pagamentosCancelados)) ">
                          Status: Parcialmente Aprovado
                          <p v-if="(item.pagamentosReprovados > 0) || (item.pagamentosCancelados > 0)">
                            Existem apurações reprovadas ou canceladas
                          </p>
                        </span>
                        <span v-if="((item.pagamentosReprovados > item.pagamentosAprovados)
                          && ((item.pagamentosReprovados !== item.quantidadeAcoes) || (item.pagamentosAprovados > 0))
                          && (item.pagamentosReprovados >= item.pagamentosCancelados)) ">
                          Status: Parcialmente Reprovado
                          <p v-if="item.pagamentosAprovados > 0">
                            Existem apurações aprovadas
                          </p>
                        </span>
                        <span v-if="((item.pagamentosCancelados > item.pagamentosAprovados)
                          && ((item.pagamentosCancelados !== item.quantidadeAcoes) || (item.pagamentosAprovados > 0))
                          && (item.pagamentosCancelados >= item.pagamentosReprovados)) ">
                          Status: Parcialmente Cancelado
                          <p v-if="item.pagamentosAprovados > 0">
                            Existem apurações aprovadas
                          </p>
                        </span>
                      </v-tooltip>
                      <v-card-text @click="selectItem(item)" class="Card_Content py-0">
                        <v-row justify="center" align="center">
                          <v-col cols="12" justify="center" align="center">
                            <span v-if="isVisaoStatus">
                              {{ `${item.quantidadeAcoes > 0 ? item.quantidadeAcoes : 1} ${retornaTextoStatus(item.quantidadeAcoes, item.status)}` }}
                            </span>
                            <span v-else>
                              {{ `${item.quantidadeAcoes > 0 ? item.quantidadeAcoes : 1} ${item.quantidadeAcoes > 1 ? 'Ações' : 'Ação'}` }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="Card_Actions" v-if="item.codAcaoPrevia">
                        <pagamento-acao-massiva-fluxo
                          class="-3"
                          v-if="canAccessAprovMass && ((item.pagamentosAguardandoAprovacao > 0) || (item.pagamentosPendentes > 0)
                            || (item.pagamentosEmAnalise > 0) || (item.pagamentosVencidos > 0))"
                          :idAcaoMassiva="item.codAcaoPrevia"
                          :status="item.status"
                          @AprovacaoMassivaPagamento_sucesso="reloadFluxoAtualizado()"
                          @LimpaSelecionados_sucesso="() => {}">
                        </pagamento-acao-massiva-fluxo>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="validarAcao">
    </confirm>
    <modal-importacao-pagamento
      ref="modalImportacaoPagamento"
      @RECARREGAR_PAGAMENTO="buscar">
    </modal-importacao-pagamento>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { listarPagamentosNivel2 } from '../../../common/resources/liquidacao/pagamento';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import ModalImportacaoPagamento from './ModalImportacaoPagamento';
import PagamentoAcaoMassivaFluxo from './PagamentoAcaoMassivaFluxo';

export default {
  name: 'PagamentoDadosNivel2Agrupado',
  components: {
    Confirm,
    ModalImportacaoPagamento,
    PagamentoAcaoMassivaFluxo,
  },
  props: {
    filtros: Object,
    objetoAcao: Object,
  },
  data() {
    return {
      status: null,
      idCliente: null,
      idFornecedor: null,
      origem: null,
      pesquisaCards: '',
      mensagemConfirmacao: '',
      objetoSelecionado: '',
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      acaoConfirmacao: null,
      idPagamento: null,
      numNota: null,
    };
  },
  filters: {
    truncate(value, limit) {
      if (value && value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  watch: {
    filtroLiquidacaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
        this.pagination.page = 1;
        this.filtrar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoAcao) {
          this.filtrar();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('implantacao', [
      'implantacao',
    ]),
    isVisaoStatus() {
      return !!this.status;
    },
    isVisaoCliente() {
      return !!this.idCliente;
    },
    isVisaoFornecedor() {
      return !!this.idFornecedor;
    },
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel2',
      'filtroLiquidacaoAcao',
    ]),
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    canAccessAprovMass() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APROV_PAG_MASSIVA').length;
    },
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel2',
    ]),
    selectItem(item) {
      let params;

      if (item.codAcaoPrevia) {
        params = {
          idAcaoPrevia: item.codAcaoPrevia,
          objetoAcao: item,
        };
      } else {
        params = {
          idAcao: item.codAcao,
          objetoAcao: item,
        };
      }

      if (this.isVisaoStatus) {
        params.status = this.status;
        if (params.idAcaoPrevia) {
          this.$router.push({ name: 'listarPagamentosNivel3StatusMassivo', params });
        } else {
          this.$router.push({ name: 'listarPagamentosNivel3Status', params });
        }
      }

      const query = { origem: this.origem };

      if (this.isVisaoCliente) {
        params.idCliente = this.idCliente;
        if (params.idAcaoPrevia) {
          this.$router.push({ name: 'nivel2PagamentoClienteMassivo', params, query });
        } else {
          this.$router.push({ name: 'nivel3PagamentoCliente', params, query });
        }
      }

      if (this.isVisaoFornecedor) {
        params.idFornecedor = this.idFornecedor;
      }
    },
    retornaTextoStatus(quantidade, status) {
      let texto = '';

      if (status === 'APROVADO') {
        if (quantidade > 1) {
          texto = 'Ações Aprovadas';
        } else {
          texto = 'Ação Aprovada';
        }
      } else if (status === 'PENDENTE') {
        if (quantidade > 1) {
          texto = 'Ações Pendentes';
        } else {
          texto = 'Ação Pendente';
        }
      } else if (status === 'AGUARDANDO_APROVACAO') {
        if (quantidade > 1) {
          texto = 'Ações Aguardando Aprovação';
        } else {
          texto = 'Ação Aguardando Aprovação';
        }
      } else if (status === 'EM_ANALISE') {
        if (quantidade > 1) {
          texto = 'Ações em Análise';
        } else {
          texto = 'Ação em Análise';
        }
      } else if (status === 'REPROVADO') {
        if (quantidade > 1) {
          texto = 'Ações Reprovadas';
        } else {
          texto = 'Ação Reprovada';
        }
      } else if (status === 'VENCIDO') {
        if (quantidade > 1) {
          texto = 'Ações Vencidas';
        } else {
          texto = 'Ação Vencida';
        }
      } else if (status === 'APURACAO_PREVIA') {
        if (quantidade > 1) {
          texto = 'Ações em Apuração Prévia';
        } else {
          texto = 'Ação em Apuração Prévia';
        }
      } else if (status === 'AGUARDANDO_PAGAMENTO') {
        if (quantidade > 1) {
          texto = 'Ações Aguardando Pagamento';
        } else {
          texto = 'Ação Aguardando Pagamento';
        }
      } else if (status === 'LIQUIDADO') {
        if (quantidade > 1) {
          texto = 'Ações Liquidadas';
        } else {
          texto = 'Ação Liquidadas';
        }
      } else if (status === 'SEM_FATURAMENTO') {
        if (quantidade > 1) {
          texto = 'Ações Sem Faturamento';
        } else {
          texto = 'Ação Sem Faturamento';
        }
      }

      return texto;
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    reloadFluxoAtualizado() {
      this.lista = [];

      if (this.filtroLiquidacaoAcao) {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
      }

      this.buscar();
    },
    buscar() {
      this.consultar(listarPagamentosNivel2, (response) => {
        this.lista = response.data.resposta;
        this.rowsPerPageItems = [4, 8, 12, 16, 20, 24, 28, 32, 36, 40];
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.preencherFiltrosNivel1Vuex();

        const filtrosTotalizador = {
          ...this.filtrosAplicados,
          id_acao_previa_n3: null,
        };

        this.$emit('Liquidacao__recarregarTotoalizador', filtrosTotalizador);
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.objetoSelecionado) {
        if (this.buscando) return;
        this.buscando = true;

        const params = {
          ...this.filtrosAplicados,
          filtro: this.pesquisaCards,
          tamanhoPagina: this.pagination.itemsPerPage,
          numeroPagina: this.pagination.page,
        };

        if (this.isVisaoCliente) {
          params.id_cliente = this.idCliente;
        } else if (this.isVisaoFornecedor) {
          params.id_fornecedor = this.idFornecedor;
        } else {
          params.status = this.status;
        }

        consulta(params, this.$resource)
          .then(sucesso)
          .catch((err) => {
            this.finalizandoBusca();
            this.$error(this, err);
          });
      }
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    abrirModalConfirmacao(acao, item) {
      this.idPagamento = item.id;
      this.numNota = item.num_nota;
      if (acao === 'cancelar') {
        this.mensagemConfirmacao = this.$t('message.deseja_cancelar_pagamento');
        this.acaoConfirmacao = null;
      } else if (acao === 'reenviar') {
        this.mensagemConfirmacao = this.$t('message.deseja_reenviar_pagamento');
        this.acaoConfirmacao = null;
      } else {
        this.mensagemConfirmacao = this.$t('message.deseja_editar_item');
        this.acaoConfirmacao = null;
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    validarAcao() {
      if (this.acaoConfirmacao) {
        this.acaoConfirmacao();
      }
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.idCliente = this.$route.params.idCliente;
      this.idFornecedor = this.$route.params.idFornecedor;
      this.origem = this.$route.query.origem;
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroLiquidacaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoAcaoNivel2.quantidatePorPagina;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    abrirModalImportacao() {
      if (this.$refs.modalImportacaoPagamento) {
        this.$refs.modalImportacaoPagamento.toggle();
      }
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
    this.objetoSelecionado = this.objetoAcao;
  },
  mounted() {
    if (this.filtroLiquidacaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroLiquidacaoAcao && this.objetoSelecionado) {
      this.filtrosAplicados = this.filtroLiquidacaoAcao;
      this.filtrar();
    }
  },
};
</script>

<style>
.Container_Nivel2_Pagamento .Card_Pagamento {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Pagamento .Card_Title, .Container_Nivel2_Pagamento .Card_Content {
  color: #757575;
}
.clickable {
  cursor: pointer;
}
</style>
<style src="../../../assets/css/card-status-pagamento.css" lang="css"></style>
